import useWidth from '../hooks/useWidth'
import styles from './Wrapper.module.css'

const Wrapper =  ({children}) => {
  const width = useWidth()
  return (
    <div className={styles['wrapper_'+width]}>{children}</div>
  )
}

export default Wrapper
