import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../components/misc/Loading";

const Front = lazy(() => import("../pages/Front"));
const Featured = lazy(() => import("../pages/Featured"));
const Blog = lazy(() => import("../pages/Blog"));
const Summoner = lazy(() => import("../pages/Summoner"));
const SummonerByPuuid = lazy(() => import("../pages/SummonerByPuuid"));
const Live = lazy(() => import("../pages/Live"));

const MyRoutes = () => {
  return (
    <Routes>
      <Route
        path="/featured"
        element={
          <Suspense fallback={<Loading />}>
            <Featured />
          </Suspense>
        }
      />
      <Route
        path="/blog/:article/"
        element={
          <Suspense fallback={<Loading />}>
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="/info/:article/"
        element={
          <Suspense fallback={<Loading />}>
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="/:summoner/live"
        element={
          <Suspense fallback={<Loading />}>
            <Live />
          </Suspense>
        }
      />
      <Route
        path="/puuid/:puuid/"
        element={
          <Suspense fallback={<Loading />}>
            <SummonerByPuuid />
          </Suspense>
        }
      />
      <Route
        path=":summoner"
        element={
          <Suspense fallback={<Loading />}>
            <Summoner />
          </Suspense>
        }
      />
      <Route
        index
        element={
          <Suspense fallback={<Loading />}>
            <Front />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default MyRoutes;
