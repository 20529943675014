import React from 'react'
import { Link} from 'react-router-dom'
import LanguageDropDown from './LanguageDropDown'
import Logo from '../misc/Logo'
import SearchBar from './SearchBar'
import TierList from '../images/icons/TierList'
import Wrapper from '../misc/Wrapper'
import useWidth from '../hooks/useWidth'
import styles from './TopBar.module.css'

const TopBar = () => {
  const width = useWidth()
  return (
    <div className={styles.topbar}>
      <Wrapper>
        <div className={styles[width]}>
          <div>
            <Link to="/">
              <div className={styles.logo}>
                <Logo height="35" />
              </div>
            </Link>
          </div>
          <div></div>
            { width !=='tiny' && <div><a href="https://lolalytics.com/lol/tierlist/"><div className={styles.tierlist}><TierList />Tier List</div></a></div> }
            <div></div>
          <div className={styles.lang}><LanguageDropDown /></div>
          <div></div>
          <SearchBar width={width} />
        </div>
      </Wrapper>
    </div>
  )
}
export default TopBar
