import React, { useState } from "react";

export const LangContext = React.createContext(null);

const languageList = [
  "eng",
  "chn",
  "cze",
  "deu",
  "esp",
  "fra",
  "grc",
  "hun",
  "ita",
  "jpn",
  "kor",
  "pol",
  "por",
  "rom",
  "rus",
  "tha",
  "tur",
  "vnm",
];

export const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState(false);
  const params = new URLSearchParams(window.location.search);

  if (
    languageList.indexOf(params.get("lang")) !== -1 &&
    params.get("lang") !== localStorage.getItem("language")
  ) {
    localStorage.setItem("language", params.get("lang"));
  }
  if (!localStorage.getItem("language"))
    localStorage.setItem("language", "eng");

  const language =
    localStorage.getItem("language") !== "null"
      ? localStorage.getItem("language")
      : "eng";

  if (lang !== language) setLang(language);
  const contextValue = {
    lang,
    setLang,
  };

  return (
    <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>
  );
};
