import Wrapper from './Wrapper'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <Wrapper>
        <Link to="/info/faq/">FAQ</Link>
        <Link to="/info/privacy/">Privacy</Link>
      </Wrapper>
    </div>
  )
}

export default Footer
