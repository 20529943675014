import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../hooks/useOnClickOutside";
import styles from "./SearchBar.module.css";

export const SearchBar = ({ width }) => {
  const [value, setValue] = useState("");
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  let navigate = useNavigate();

  const handleChange = (e) => setValue(e.target.value);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (value.length > 0) {
        navigate(
          `/${(e.target.value.split("#")[0] ?? "")
            .replace(/\s+/g, "")
            .toLowerCase()}-${(
            e.target.value.split("#")[1] ?? ""
          ).toLowerCase()}`
        );
        setValue("");
      }
    } else if (e.keyCode === 27) {
      setValue("");
    }
  };

  useEffect(() => {
    let focusTimeOut;
    inputRef.current.addEventListener("blur", (e) => {
      focusTimeOut = setTimeout(() => {
        if (document.activeElement !== inputRef.current) {
          setValue("");
        }
      }, 300);
    });
    return () => clearTimeout(focusTimeOut);
  }, []);

  useOnClickOutside(searchRef, () => {
    setValue("");
  });

  return (
    <div ref={searchRef} className={styles.search}>
      <label className={styles.label}>
        <input
          className={styles["box" + (width === "tiny" ? "mobile" : "")]}
          ref={inputRef}
          placeholder={"summoner#NA1"}
          value={value}
          autoFocus
          tabIndex="0"
          autoComplete="off"
          spellCheck="false"
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </label>
      {value.length > 0 && (
        <div className={styles.results}>
          <div className={styles.row}>
            <div className={styles.fullwidth}>
              <Link
                to={{
                  pathname: `/${(value.split("#")[0] ?? "")
                    .replace(/\s+/g, "")
                    .toLowerCase()}-${(
                    value.split("#")[1] ?? ""
                  ).toLowerCase()}`,
                }}
              >
                <div className={styles.summoner}>
                  {value.split("#")[0]}#
                  {(value.split("#")[1] ?? "").length > 0
                    ? value.split("#")[1]
                    : "NA1"}
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
