import { BrowserRouter as Router } from 'react-router-dom'
import { LangContextProvider } from './LangContext'
import { TipContextProvider } from './TipContext'
import TopBar from '../components/topbar/TopBar'
import Footer from '../components/misc/Footer'
import MyRoutes from './Route'
import './App.css'

const App = () => {
  return (
    <Router basename="/">
      <LangContextProvider>
        <TipContextProvider>
          <TopBar/>
          <MyRoutes />
          <Footer/>
        </TipContextProvider>
      </LangContextProvider>
    </Router>
  )
}

export default App
